<template>
  <div>
    <!-- 头部 -->
    <div style="overflow: hidden; max-width: 100vw">
      <section class="section p0 panel header_video">
        <div class="col-lg-12 p0 container_max">
          <video
            autoplay
            muted
            playsInline="{true}"
            class="video_box"
            webkit-playsinline="true"
            x-webkit-airplay="true"
            x5-playsinline="true"
            x5-video-orientation="portraint"
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/moxun.mp4"
          ></video>

          <h1
            class="T128px_Regular colorfff header_title wow animate__fadeInUp"
          >
            {{ $t("moXun.headerTitle") }}
          </h1>

          <div class="header_video_bottom flex-wb">
            <div class="left_box col-lg-6">
              <header class="T36px_Regular colorfff">
                <span class="jb_title">{{ $t("moXun.jb_title1") }}</span>
                <span class="xian"></span>
                <span>{{ $t("moXun.jb_title2") }}</span>
              </header>
              <div class="T18px_Regular colorfff left_box_tips">
                {{ $t("moXun.left_box_tips") }}
              </div>
            </div>
            <div
              class="right_box col-lg-6 flex-wb"
              style="align-items: flex-start"
            >
              <div
                class="col-lg-4 flex-cc"
                v-for="(item, index) in headerList"
                :key="index"
              >
                <header class="right_box_header">
                  <img :src="item.url" style="width: 1.6rem" alt="" />
                  <span
                    class="T24px_Regular colorfff max_title"
                    :style="
                      index == 2
                        ? 'border-right: .0313rem solid transparent;'
                        : ''
                    "
                    >{{ item.title }}</span
                  >
                </header>
                <div class="T18px_Regular colorfff flex-c chlie_box">
                  <span v-for="(items, ins) in item.chlie" :key="ins">{{
                    items
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- 项目背景 -->
    <section class="section p0 project_bj_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF"> {{ $t("moXun.project_bj_title1") }}</span>
          <span class="colorfff">{{ $t("moXun.project_bj_title2") }}</span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          <span class="T24px_Regular wow animate__fadeInUp">
            {{ $t("moXun.project_bj__p1") }}
          </span>
          {{ $t("moXun.project_bj__p1s") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          <span class="T24px_Regular">
            {{ $t("moXun.project_bj__p2") }}
          </span>
          {{ $t("moXun.project_bj__p2s") }}
        </div>
      </div>
      <!-- 轮播图 -->
      <div class="project_bj_image">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="item in 5" :key="item" class="swiper-slideb">
            <div class="img_box">
              <img
                :src="`https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/round${item}.png`"
                class="img_box_image"
                :class="`round${item}_image`"
                alt=""
              />
            </div>
          </swiper-slide>
        </swiper>
        <img
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/swiper_arr.png"
          class="btn-left swiper_arr_icon"
          alt=""
        />
        <img
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/swiper_arr.png"
          class="btn-right swiper_arr_icon"
          alt=""
        />
      </div>
    </section>

    <!-- 分析市场 -->
    <section class="section project_challenge_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">{{ $t("moXun.challenge_title1") }}</span>
          <span class="colorfff">{{ $t("moXun.challenge_title2") }}</span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("moXun.challenge_p1") }}
        </div>

        <div class="col-12 flex-w labe_item_box">
          <div class="col-4 flex-c">
            <span class="labe_item T24px_Regular colorfff">
              {{ $t("moXun.challenge_p1_chil1") }}
            </span>
            <span class="labe_item T24px_Regular colorfff">{{
              $t("moXun.challenge_p1_chil2")
            }}</span>
            <span class="labe_item T24px_Regular colorfff">{{
              $t("moXun.challenge_p1_chil3")
            }}</span>
          </div>
          <div class="col-4 flex-c">
            <span class="labe_item T24px_Regular colorfff">{{
              $t("moXun.challenge_p1_chil4")
            }}</span>
            <span class="labe_item T24px_Regular colorfff">{{
              $t("moXun.challenge_p1_chil5")
            }}</span>
            <span class="labe_item T24px_Regular colorfff">{{
              $t("moXun.challenge_p1_chil6")
            }}</span>
          </div>
        </div>

        <header class="derive_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">{{ $t("moXun.derive_header1") }}</span>
          <span class="colorfff">{{ $t("moXun.derive_header2") }}</span>
        </header>

        <div class="derive_info_box col-12">
          <div
            class="derive_info_item flex-w"
            v-for="(item, index) in deriveList"
            :key="index"
          >
            <img
              class="derive_info_image"
              :class="`derive_image${index}`"
              :src="item.url"
              alt=""
            />

            <div class="info_box flex-c">
              <span
                class="T24px_Regular colorfff title_bj wow animate__fadeInUp"
                >{{ item.title }}</span
              >
              <span
                class="info_text T16px_Regular colorfff wow animate__fadeInUp"
                data-wow-delay=".2s"
              >
                {{ item.infoText }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel_box flex-c col-12">
        <el-carousel indicator-position="outside" type="card" :autoplay="false">
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <div class="flex-cc carousel_item">
              <img :src="item.url" class="carousel_img" alt="" />
              <span class="T12px_Regular colorfff">{{ item.title }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
    <!-- 项目总结 -->
    <section class="section project_summarize_box">
      <div class="container">
        <header
          class="project_bj_header T36px_Regular wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          <span class="color1D73FF">{{ $t("moXun.project_summarize1") }}</span>
          <span class="colorfff">{{ $t("moXun.project_summarize2") }}</span>
        </header>
        <div class="proj_bj_text proj_bj_p1 T16px_Regular colorfff">
          {{ $t("moXun.project_summarize_proj_bj_text") }}
        </div>
      </div>

      <div class="customized_box flex-wb">
        <div class="flex-c">
          <span class="T36px_Regular colorfff">
            {{ $t("customized.title") }}
            <span class="color1D73FF">{{ $t("customized.title1") }}</span>
          </span>
          <span class="T16px_Regular color818181">
            {{ $t("customized.tipe") }}
          </span>
        </div>
        <div class="flex-c">
          <span class="T14px_Regular colorfff tips_net">
            {{ $t("customized.tipe_ri") }}
          </span>
          <span
            @click="routerBtn('/contact')"
            class="T20px_Regular colorfff my_btn animation_right"
          >
            {{ $t("customized.btn") }}
          </span>
        </div>
      </div>

      <div class="case_box">
        <header class="case_header T36px_Regular colorfff">
          {{ $t("moXun.genduo") }}
        </header>
        <div class="case_list flex-w col-12">
          <div
            class="case_item flex-c col-lg-4"
            @click="routerBtn(item.viewUrl)"
            v-for="(item, index) in caseList"
            :key="index"
          >
            <div class="case_image_box">
              <img :src="item.url" class="case_image" alt="" />
            </div>
            <span class="T24px_Regular colorfff case_title">
              {{ item.title }}
            </span>
            <span class="T14px_Regular colorfff case_tips">
              {{ item.tips }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

import { WOW } from "wowjs";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 30,
        // loop: true,
        autoplay: {
          // delay: 3000,
          // stopOnLastSlide: false,
          // disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          //下一张
          nextEl: ".btn-right", //下一张标签类名可以自定义
          //上一张
          prevEl: ".btn-left", //上一张标签类名可以自定义
        },
      },

      deriveList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/derive_info0.png",
          title: this.$t("moXun.deriveList1_title"),
          infoText: this.$t("moXun.deriveList1_infoText"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/derive_info1.png",
          title: this.$t("moXun.deriveList2_title"),
          infoText: this.$t("moXun.deriveList2_infoText"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/derive_info2.png",
          title: this.$t("moXun.deriveList3_title"),
          infoText: this.$t("moXun.deriveList3_infoText"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/derive_info3.png",
          title: this.$t("moXun.deriveList4_title"),
          infoText: this.$t("moXun.deriveList4_infoText"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/derive_info4.png",
          title: this.$t("moXun.deriveList5_title"),
          infoText: this.$t("moXun.deriveList5_infoText"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/derive_info5.png",
          title: this.$t("moXun.deriveList6_title"),
          infoText: this.$t("moXun.deriveList6_infoText"),
        },
      ],
      headerList: [
        {
          title: this.$t("moXun.headerList1_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s1.png",
          chlie: [
            this.$t("moXun.headerList1_chlie1"),
            this.$t("moXun.headerList1_chlie2"),
            this.$t("moXun.headerList1_chlie3"),
            this.$t("moXun.headerList1_chlie4"),
          ],
        },
        {
          title: this.$t("moXun.headerList2_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s2.png",
          chlie: [
            this.$t("moXun.headerList2_chlie1"),
            this.$t("moXun.headerList2_chlie2"),
            this.$t("moXun.headerList2_chlie3"),
          ],
        },
        {
          title: this.$t("moXun.headerList3_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s3.png",
          chlie: [
            this.$t("moXun.headerList3_chlie1"),
            this.$t("moXun.headerList3_chlie2"),
            this.$t("moXun.headerList3_chlie3"),
          ],
        },
      ],

      carouselList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/carousel1.png",
          title: this.$t("moXun.carouselList1_title"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/carousel1.png",
          title: this.$t("moXun.carouselList2_title"),
        },
      ],

      caseList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/case1.png",
          title: this.$t("case.projecList4_name"),
          tips: this.$t("case.projecList4_tips"),
          viewUrl: "/ninja",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/xueyuan.png",
          title: this.$t("case.projecList3_name"),
          tips: this.$t("case.projecList3_tips"),
          viewUrl: "/college",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/sanhj.png",
          title: this.$t("case.projecList2_name"),
          tips: this.$t("case.projecList2_tips"),
          viewUrl: "/sanhaiJing",
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100); // 延迟 100ms，确保页面加载完成
    this.slideInit();
    const wow = new WOW();
    wow.init();
  },
  methods: {
    /**
     * 滑动页面效果初始化
     */
    slideInit() {
      gsap.registerPlugin(ScrollTrigger);
      let panels = gsap.utils.toArray(".panel");
      panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );
      panels.forEach((panel) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom",
          pin: true,
          pinSpacing: false,
        });
      });
    },
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */

    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  background: #15171d;
}

.header_video {
  max-width: 100vw;
  overflow: hidden;
  .container_max {
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    max-width: 100vw;
    .header_title {
      position: absolute;
      top: 4.375rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      z-index: 2;
    }
  }
  .container_max::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 14.1875rem;
    position: absolute;
    top: 0;
    background: rgba(22, 39, 50, 0.74);
    z-index: 1;
  }
  .container_max::before {
    content: "";
    display: inline-block;
    width: 110%;
    height: 3.6875rem;
    background: #15171d;
    filter: blur(0.3375rem);
    position: absolute;
    // padding: 0 .5313rem;
    top: 12.1875rem;
    left: -0.5313rem;
    z-index: 2;
  }

  .video_box {
    object-fit: cover;
    width: 100vw;
    height: 14.1875rem;
    background: rgba(22, 39, 50, 0.74);
  }

  .header_video_bottom {
    width: 100vw;
    height: calc(100vh - (14.1875rem));
    padding: 0 5.875rem;
    padding-top: 1.75rem;
    align-items: flex-start;
    background: #15171d;

    .jb_title {
      background-image: -webkit-linear-gradient(
        70.4152078867778deg,
        #1d73ff 50%,
        #010711 100%,
        #15171d 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      height: 1.4063rem;
      line-height: 1.3125rem;
    }

    .xian {
      display: inline-block;
      height: 0.7813rem;
      border: 0.0313rem solid #bdbdbd;
      margin: 0 0.8125rem 0 0.5rem;
    }

    .left_box_tips {
      margin-top: 0.9375rem;
      width: 21.375rem;
      line-height: 1.3125rem;
      padding-bottom: 3.9688rem;
      text-align: justify;
    }

    .right_box {
      .col-lg-4:last-child(1) {
        .right_box_header {
          .max_title {
            border-right: 0.0313rem solid transparent;
          }
        }
      }

      .right_box_header {
        .max_title {
          border-right: 0.0313rem solid #bdbdbd;
          height: 0.7813rem;
          padding-right: 1rem;
        }
      }

      .max_title {
        margin-left: 0.7813rem;
      }

      .chlie_box {
        margin-top: 1.25rem;

        span {
          margin-bottom: 0.625rem;
        }
      }
    }
  }
}

.project_bj_box {
  background: #15171d;
  padding-top: 1.25rem !important;
  max-width: 100vw;
  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 1.5625rem;
    }

    .proj_bj_text {
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
      text-align: justify;
    }
  }

  .project_bj_image {
   
    background: linear-gradient(
      140deg,
      #1d73ff 0%,
      #162338 35%,
      #161f2f 47%,
      #15171d 100%
    );
    background-size: 100% 15.1563rem;
    background-position: bottom;
    background-repeat: no-repeat;
    // min-height: 15.6875rem;
    height: 15.1563rem;
    margin-top: 5.3125rem;
    display: flex;
    align-items: center;
    position: relative;

    .swiper_arr_icon {
      position: absolute;
      width: 2.5625rem;
      height: 2.5625rem;
      z-index: 9;
      right: 5.9375rem;

      &.btn-left {
        left: 5.9375rem;
        transform: rotate(180deg);
      }
    }

    .swiper {
      height: 15.1563rem;
      width: 100%;
      overflow: unset !important;
    }

    .img_box_image {
      width: 100%;
      height: 100%;
    }

    // .round1_image {
    //   width: 9.8438rem;
    //   height: 10.1563rem;
    // }
  }
}

.project_challenge_box {
  background: #15171d;
  padding-top: 4.6875rem;
  padding-bottom: 0;
  max-width: 100vw;
  .container {
    margin: 0 0 0 13.5rem;
    max-width: calc(100vw - 13.5rem);

    .proj_bj_p1 {
      width: 32.5938rem;
      margin-top: 0.9375rem;
    }

    .proj_bj_text {
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
      text-align: justify;
    }

    .labe_item_box {
      .labe_item {
        width: fit-content;
        display: inline-block;
        margin-top: 1.3438rem;
        padding: 0.3125rem;
        background: rgba(128, 128, 128, 0.55);
        border-radius: 1.9375rem;
      }
    }

    .derive_header {
      margin-top: 3.875rem;
    }

    .derive_info_box {
      margin-top: 2rem;
      height: 93.125rem;
      background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/moXun/derive_bj.png");
      background-size: contain;
      background-position: top left;
      background-repeat: no-repeat;

      .derive_info_item {
        margin-left: 1.25rem;
        margin-bottom: 0.7813rem;
        align-items: flex-start;

        &:nth-child(1) {
          padding-top: 0.4688rem;
        }

        .derive_image0 {
          width: 11.9063rem;
          height: 14.4375rem;
          margin-right: 7.875rem;
        }

        .derive_image1 {
          width: 13.7188rem;
          height: 14.5313rem;
          margin-right: 7rem;
        }

        .derive_image2 {
          width: 20.9688rem;
          height: 16.0625rem;
          margin-right: 2.4375rem;
        }

        .derive_image3 {
          width: 7.25rem;
          height: 15.125rem;
          margin-right: 15.0625rem;
          margin-left: 1.3438rem;
        }

        .derive_image4 {
          width: 10.4688rem;
          height: 15.9063rem;
          margin-right: 13.4688rem;
        }

        .derive_image5 {
          width: 11.6875rem;
          height: 18.7188rem;
          margin-right: 12.9063rem;
        }

        .info_box {
          width: 16.9063rem;
          margin-top: 0.7813rem;

          .title_bj {
            background: #1d73ff;
            border-radius: 1.9375rem;
            padding: 0.3125rem;
            width: fit-content;
            margin-bottom: 0.8125rem;
          }

          .info_text {
            line-height: 1.3125rem;
            text-align: justify;
          }
        }
      }
    }
  }

  .carousel_box {
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/project_challenge_bj.png");
    background-size: 100% 100%;
    background-position: left bottom;
    background-position-x: -0.75rem;
    background-repeat: no-repeat;
    height: 19.4063rem;
    margin-top: 10.3125rem;
    justify-content: flex-end;
    overflow: none;
    max-width: 100vw;

    ::v-deep .el-carousel__container,
    .el-carousel--horizontal {
      // height: 21.875rem;
      overflow: visible;
    }

    ::v-deep .el-carousel__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .carousel_item {
      // width: 100%;
      // height: 100%;
      justify-content: flex-end;
      // align-content: center;

      .carousel_img {
        width: 8.4063rem;
        height: 16.4063rem;
      }
    }
  }
}

.project_summarize_box {
  background: #15171d;
  padding-top: 2.75rem;
  max-width: 100vw;
  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 1.5625rem;
    }

    .proj_bj_text {
      width: 29.0938rem;
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
      text-align: justify;
    }
  }

  .customized_box {
    margin: 0 5.875rem;
    margin-top: 3.9375rem;
    height: 7.9375rem;
    padding: 0 1.7813rem 0 1.7188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }

  .case_box {
    margin: 0 5.875rem;
    margin-top: 5.3125rem;

    .case_list {
      margin-top: 0.9375rem;

      .case_item {
        width: calc(33.33% - 0.9rem);
        margin-right: 1.1875rem;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .case_image_box {
          width: 100%;
          height: 14.6875rem;
          margin-bottom: 0.625rem;
          overflow: hidden;

          .case_image {
            width: 100%;
            height: 100%;
            transition: all 0.8s;
          }
        }

        &:hover {
          .case_image {
            transform: scale(1.05);
            transition: all 0.8s;
          }
        }

        .case_title {
          height: 1.25rem;
        }

        &:nth-child(4) {
          margin: 0;
        }
      }
    }
  }
}

::v-deep .el-carousel__mask {
  background: transparent;
}
</style>

<style lang="less">
.swiper-wrapper {
  display: flex;
  align-items: flex-end;
}

.swiper-slideb:nth-child(1) {
  width: 9.8438rem;
  height: 10.1563rem;
  margin: 2.5rem 0;
  margin-right: 5rem !important;
}

.swiper-slideb:nth-child(2) {
  width: 9.8438rem;
  height: 9.9063rem;
  margin: 2.5rem 0;
  margin-right: 5rem !important;
}

.swiper-slideb:nth-child(3) {
  width: 27.1875rem;
  height: 20.4375rem;
  // width: 9.8438rem;
  // height: 9.9063rem;
  // margin: 2.5rem 0;
}

.swiper-slideb:nth-child(4) {
  // width: 5.6563rem;
  // height: 2.2813rem;
  width: 9.8438rem;
  height: 9.9063rem;
  margin: 2.5rem 0;
  margin-right: 5rem !important;
}

.swiper-slideb:nth-child(5) {
  width: 9.8438rem;
  height: 9.9063rem;
  margin: 2.5rem 0;
}

.swiper-slideb:nth-child(6) {
  width: 16.3438rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(7) {
  width: 13.7188rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(8) {
  width: 8.4688rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(9) {
  width: 6.6563rem;
  height: 2.2813rem;
}
</style>
